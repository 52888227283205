<template>
  <v-row justify="center">
    <v-col>
      <v-btn
        v-if="!hideButton"
        small
        dark
        color="primary"
        @click="playOrPause()"
      >
        <v-icon>{{ voiceIcon }}</v-icon
        ><span v-if="!hideButtonName">{{ $t('Play Audio') }}</span>
      </v-btn>
      <v-icon
        v-if="!hideSettings"
        right
        :color="onDarkBackground ? 'white' : 'black'"
        @click="toggleSettings()"
        >{{useVoiceIcon ? 'mdi-account-voice' : 'mdi-cog-outline' }}</v-icon
      >
      <audio :id="'localaudio' + _uid" @ended="isPlaying = false"></audio>
      <v-dialog
        persistent
        transition="dialog-top-transition"
        v-model="openSettings"
        max-width="400"
      >
        <v-card shaped light :ref="'settingsCard'">
          <v-card-title>Settings</v-card-title>
          <v-card-text class="pl-10 pr-10">
            <v-row>
              <v-select
                v-model="effectiveSettings.selectedVoiceSpeedPreference"
                :items="voiceSpeedPreference"
                item-text="speed"
                item-value="value"
                label="Select Voice Speed"
              ></v-select>
            </v-row>
            <v-row>
              <v-select
                v-model="effectiveSettings.selectedVoiceGender"
                :items="voiceGenderPreference"
                item-text="gender"
                item-value="value"
                label="Select Voice Gender"
              ></v-select>
            </v-row>
            <v-row>
              <v-switch
                v-model="effectiveSettings.muted"
                label="Mute" inset
              ></v-switch>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              raised
              color="primary"
              class="mr-5"
              @click="toggleSettings()"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
const globalSettings = {
  selectedVoiceGender: 'FEMALE',
  selectedVoiceSpeedPreference: 0.75,
  muted: false
}
export default {
  props: [
    'speechText',
    'settings',
    'hideButton',
    'hideButtonName',
    'hideSettings',
    'onDarkBackground',
    'useVoiceIcon',
    'initializeAudioLibrary'
  ],
  data: function () {
    return {
      voiceSpeedPreference: [
        { speed: 'Slow', value: 0.5 },
        { speed: 'Normal', value: 0.75 },
        { speed: 'Fast', value: 1 }
      ],
      voiceGenderPreference: [
        { gender: 'Male', value: 'MALE' },
        { gender: 'Female', value: 'FEMALE' }
      ],
      defaultSettings: {
        selectedVoiceGender: 'FEMALE',
        selectedVoiceSpeedPreference: 0.5,
        muted: false
      },
      openSettings: false,
      settingsCardHeight: 300,
      isPlaying: false
    }
  },
  computed: {
    effectiveSettings() {
      return globalSettings
    },
    voiceIcon() {
      return this.isPlaying ? 'mdi-pause' : 'mdi-play'
    }
  },
  mounted() {
    if (this.initializeAudioLibrary) this.playOrPause(true)
  },
  methods: {
    toggleSettings() {
      this.openSettings = !this.openSettings
      if (this.openSettings) {
        const height = 200
        setTimeout(() => {
          this.settingsCardHeight = height
        }, 400)
      }
    },
    toggleMute() {
      var audio = document.getElementById('localaudio' + this._uid)
      audio.muted = !audio.muted
    },
    playOrPause(muted) {
      var lang = 'gu-IN'
      var audio = document.getElementById('localaudio' + this._uid)
      if (!this.isPlaying) {
        audio.muted = this.effectiveSettings.muted || muted
        audio.src = `https://us-central1-learn-gujarati-b1468.cloudfunctions.net/tts?text=${this.speechText}&lang=${lang}&speed=${globalSettings.selectedVoiceSpeedPreference}&gender=${globalSettings.selectedVoiceGender}`
        audio.play()
        let that = this
        audio.onended = function() {
          audio.muted = that.effectiveSettings.muted
        }
        this.isPlaying = true
      } else {
        audio.pause()
        this.isPlaying = false
      }
    }
  }
}
</script>
<style scoped>
.card {
  position: relative;
  width: 100%;
  height: 68%;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 1s;
}
.voice {
  border-radius: 12px;
  box-shadow: 0 0 0 0 rgba(217, 84, 89, 1);
  animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(217, 84, 89, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0);
  }
}
.settings {
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  height: 18%;
  color: #fff;
  transition: all 0.4s;
  overflow: hidden;
  pointer-events: none;
  left: 0;
  backface-visibility: hidden;
}
.settings:before {
  position: absolute;
  content: '';
  left: 93%;
  bottom: 0px;
  width: 100%;
  height: 104%;
  z-index: 0;
  opacity: 1;
  color: #fff;
  background-color: #030303;
  transition: all 0.4s;
  transform: skewX(-50deg);
  backface-visibility: hidden;
}
.settingsIcon {
  z-index: 0;
}
.settingsIcon:hover {
  animation: infinite-spinning 1s ease-out 0s normal;
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.settingsCard {
  position: absolute;
  z-index: 1;
}
.settingsCardClose {
  position: absolute;
  bottom: 10px;
  left: 36%;
}
</style>
